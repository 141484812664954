// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

.ant-layout-has-sider ::-webkit-scrollbar {
  width: 5px;
}

.ant-layout-has-sider ::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.3);
}

.ant-layout-has-sider ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.ant-layout-sider-zero-width-trigger {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  border-radius: 0 0 0 6px;
  padding: 10px;
  font-size: 23px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

ul.ant-menu.ant-menu-sub.ant-menu-inline {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    z-index: 1;
    left: 30px;
  }
}
.ant-menu-item-icon {
  font-size: 16px;
  width: 16px;
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-page-header {
  border-radius: var(--space-4);
}

// Breadcrumb fix
.ant-breadcrumb li {
  display: flex;
  align-items: center;
}

.ant-breadcrumb-link {
  display: inline-flex;
  gap: 4px;
  align-items: center;
}

.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 0;
}

.ant-pagination-prev button,
.ant-pagination-next button,
.ant-avatar.ant-avatar-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

// TABLE
.ant-table-container {
  border-radius: 10px;
  overflow: hidden;

  table {
    background: #f9fafb;

    thead th {
      background: #f2f4f7;
    }
  }
}

// BTN fix
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: #2a132e;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}

.ant-input:focus,
.ant-input-focused {
  border-color: #0075c0;
  box-shadow: 0 0 0 2px rgb(0 117 192 / 20%);
}
.ant-input:hover {
  border-color: #0075c0;
}

::selection {
  background: #0075c0;
}

html {
  --antd-wave-shadow-color: #0075c0;
}

.ant-tree {
  .ant-tree-treenode {
    border: 1px solid #eee;
    border-bottom: 0;
    padding: 0;
    align-items: center;

    &:last-of-type {
      border-bottom: 1px solid #eee;
    }

    .ant-tree-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    }

    .ant-tree-draggable-icon {
      display: none;
    }

    .ant-tree-node-content-wrapper {
      padding: 8px 0;
    }
  }
}

.ant-collapse-header {
  align-items: center !important;

  &-text {
    width: 100%;
  }
}

.ant-collapse-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.required-mark {
  ::before {
    display: inline-block;
    margin-right: 4px;
    color: var(--colors-danger600);
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

// TAG
.ant-tag {
  // border-radius: 100px;
  border: 0;
  font-weight: 500;
  background: var(--colors-neutral50);
  border-width: 1px;
  border-style: solid;
  border-color: var(--colors-neutral200);
  color: var(--colors-text);

  &.ant-tag-default {
    background: var(--colors-neutral100);
    color: var(--colors-text);
    border-color: var(--colors-neutral300);
  }

  &.ant-tag-green,
  &.ant-tag-success {
    background-color: var(--colors-green50);
    color: var(--colors-green600);
  }

  &.ant-tag-red,
  &.ant-tag-error {
    background-color: var(--colors-danger50);
    color: var(--colors-danger600);
  }

  &.ant-tag-blue {
    background-color: var(--colors-blue50);
    color: var(--colors-blue700);
    border-color: var(--colors-blue300);
  }

  &.ant-tag-yellow,
  &.ant-tag-warning {
    background-color: var(--colors-yellow50);
    color: var(--colors-yellow600);
    border-color: var(--colors-yellow300);
  }
}

.ant-select-clear {
  top: 43%;
}

.documents-collapse {
  .ant-collapse-content {
    border-top: 0;
  }
}

.custom-card {
  background: transparent;
  overflow: hidden;

  .custom-card-head {
    background: #efefef;
    padding-left: 0;
  }

  .custom-card-body {
    background: #fff;
  }

  .ant-tabs-tab {
    padding: 12px 16px !important;
    border-right: 1px solid #ddd;

    + .ant-tabs-tab {
      margin-left: 0 !important;
    }

    &:has(+ .ant-tabs-tab-active) {
      border-color: transparent;
    }
  }

  .ant-tabs-tab-active {
    background: #fff;
    border-color: transparent;
  }

  .ant-tabs-ink-bar {
    top: 0;
  }
}